import { useEffect, useState } from "react";
import styled from "styled-components";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import StopIcon from "@mui/icons-material/Stop";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useAppDispatch, useAppSelector } from "model/rootReducer";

import { HintPopper } from "common/components";
import { useTranslation } from "common/locales";
import { dryMatterToColor } from "common/utils/grazing-logic";
import AnimalGroups from "features/fieldGroupList/AnimalGroups";
import {
  getAllArableFieldGeoms,
  getAllFieldGeoms,
  getTotalAvgDM,
  selectCurrentFarm,
  setZoomToGeom,
} from "model";
import { useHistory } from "react-router-dom";
import AddFab from "./AddFab";
import FarmSwitch from "./FarmSwitch";

import { closeDrawer } from "model/appSlice";
import { mixpanel } from "../../common/analytics";

export default function FarmView() {
  const farmDryMatterNow = useAppSelector(getTotalAvgDM);
  const [hintContainer, setHintContainer] = useState<HTMLDivElement | null>(
    null
  );
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const farm = useAppSelector(selectCurrentFarm);
  const loading = useAppSelector((state) => state.app.loading === "pending");
  const fieldLoadStatus = useAppSelector((state) => state.fields.status);
  const growthRate = useAppSelector((state) => state.app.growthRate);
  const fieldGeoms = useAppSelector(getAllFieldGeoms);
  const arableFieldGeoms = useAppSelector(getAllArableFieldGeoms);
  const drawerOpen = useAppSelector((state) => state.app.drawerOpen);
  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (fieldGeoms.features.length > 0) {
      dispatch(setZoomToGeom(fieldGeoms));
    } else if (arableFieldGeoms.features.length > 0) {
      dispatch(setZoomToGeom(arableFieldGeoms));
    }
    {
      if (farm?.geom) {
        dispatch(setZoomToGeom(farm.geom));
      }
    }
    // we need to disable lint here because we cannot have dependency on an object - it would keep zooming otherwise
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm?.guid, dispatch]);

  const fieldHintOpen =
    fieldLoadStatus === "success" &&
    fieldGeoms.features.length === 0 &&
    arableFieldGeoms.features.length === 0;

  const animalGroupHintOpen = false;
  const hintOpen =
    (fieldHintOpen || animalGroupHintOpen) &&
    Boolean(hintContainer) &&
    drawerOpen;

  return (
    <>
      <Container>
        <div />
      </Container>
      {loading && (
        <FarmTotals>
          <List dense disablePadding>
            <ListItem>
              <FarmSwitchContainer>
                <Skeleton variant="text" width={100} />
              </FarmSwitchContainer>
              <ListItemSecondaryAction>
                <Skeleton variant="rectangular" width={48} height={24} />
              </ListItemSecondaryAction>
            </ListItem>
            {[1, 2, 3].map((n) => (
              <ListItem key={`farm-total-skeleton-${n}`}>
                <ListItemIcon>
                  <Skeleton variant="rectangular" width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  primary={<Skeleton variant="text" width={110} />}
                  secondary={<Skeleton variant="text" width={130} />}
                />
              </ListItem>
            ))}
          </List>
        </FarmTotals>
      )}
      {!loading && (
        <FarmTotals>
          <List dense disablePadding>
            <ListItem>
              <Box
                sx={{ display: "flex", width: "100%", alignItems: "center" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{ flexGrow: 1 }}
                  onClick={() => {
                    mixpanel.track("Home from farm view clicked");
                    history.push(`/initial-screen-select`);
                  }}
                >
                  {t("home.button.title")}
                </Button>
                {isMobile && (
                  <IconButton
                    sx={{ ml: 1 }}
                    onClick={() => dispatch(closeDrawer())}
                  >
                    <Box sx={{ display: "flex", mr: -2 }}>
                      <ChevronLeftIcon />
                      <ChevronLeftIcon sx={{ ml: -2 }} />
                    </Box>
                  </IconButton>
                )}
              </Box>
            </ListItem>
            <ListItem>
              <ListItemText>
                <FarmSwitch
                  switchToFarmView={true}
                  fontVariant={"subtitle2"}
                  fontColor={"black"}
                />
              </ListItemText>
              <ListItemSecondaryAction>
                {!isMobile && <AddFab />}
                <div ref={setHintContainer}></div>
                <HintPopper
                  open={hintOpen}
                  title={t(
                    fieldHintOpen
                      ? "hint.welcome.title"
                      : "hint.animalGroups.title"
                  )}
                  text={t(
                    fieldHintOpen
                      ? "hint.welcome.text"
                      : "hint.animalGroups.text"
                  )}
                  parentEl={hintContainer}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {farmDryMatterNow ? (
                  <ListItem>
                    <ListItemIcon>
                      <StopIcon
                        htmlColor={dryMatterToColor(farmDryMatterNow)}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={t("farm.totalDM.label")}
                      secondary={`${farmDryMatterNow} ${t(
                        "farm.totalDM.unit"
                      )}`}
                    />
                  </ListItem>
                ) : null}
                {growthRate ? (
                  <ListItem>
                    <ListItemIcon>
                      <ArrowUpwardIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t("farm.growth.label")}
                      secondary={`${
                        growthRate ? Math.round(growthRate) : ""
                      } ${t("farm.growth.unit")}`}
                    />
                  </ListItem>
                ) : null}
              </Box>
              {isMobile && (
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    pr: 2,
                    pb: 2,
                  }}
                >
                  <AddFab />
                </Box>
              )}
            </Box>
          </List>
        </FarmTotals>
      )}
      <Divider />
      <Container>
        <AnimalGroupContainer>
          <AnimalGroups />
        </AnimalGroupContainer>
      </Container>
    </>
  );
}

const FarmTotals = styled(Container)(
  ({ theme }) => `
  padding-bottom: ${theme.spacing(2)};
`
);

const AnimalGroupContainer = styled.div`
  overflow-y: auto;
  flex-grow: 1;
`;

const FarmSwitchContainer = styled(ListItemText)`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ButtonGroupContainer = styled(ButtonGroup)(
  ({ theme }) => `
  overflow-y: auto;
  flex-grow: 1;
  padding-bottom: ${theme.spacing(2)};
`
);
