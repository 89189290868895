import { CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import { Feature, FeatureCollection } from "geojson";
import { groupBy } from "lodash";
import {
  Field,
  fieldWithAnimalsCenters,
  getAllFieldGeoms,
  getAnimalGroupColorMapping,
  getFieldsCenterWithNoTilesForNotDeletedFieldBoundaries,
  getFieldsWithNoTilesForNotDeletedFieldBoundaries,
  RootState,
  selectAllFields,
  selectAllFieldsWithNotDeletedBoundaries,
  selectTmpDeletedFeatures,
} from "model";
import { MutableRefObject } from "react";
import { Layer, MapRef, Marker, Source } from "react-map-gl";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import AnimalsLocationMarker from "./AnimalsLocationMarker";
import DiaryEntryLocationMarkerWithScroll from "./DiaryEntryLocationMarkerWithScroll";

interface Props {
  editedFieldId?: string;
  fieldId?: string;
  mapRef: MutableRefObject<MapRef | null>;
  setTooltipOverMarker: (value: boolean) => void;
}

interface MatchProps {
  id: string;
}

export default function FieldGeoJSONLayers({
  editedFieldId,
  fieldId,
  mapRef,
  setTooltipOverMarker,
}: Props) {
  const theme = useTheme();
  const editMatch = useRouteMatch<MatchProps>("/edit-field/:id");
  const fieldGeoms = useSelector(getAllFieldGeoms);
  const currentFieldGeom = useSelector(
    (state: RootState) => fieldId && state.fields.entities[fieldId]?.geom
  );
  const tmpDeletedFeatures = useSelector(selectTmpDeletedFeatures);
  const fields = useSelector(selectAllFieldsWithNotDeletedBoundaries).filter(
    (field) => field.guid !== editedFieldId
  ); // TODO: move to selector
  const fieldsByAnimalGroups = groupBy(fields, "animalGroup");
  const fieldsWithNoTiles = useSelector(
    getFieldsWithNoTilesForNotDeletedFieldBoundaries
  ).filter((fieldGuid) => fieldGuid !== editedFieldId); // TODO: move to selector
  const colorByAnimalGroupId: Record<string, string> = useSelector(
    getAnimalGroupColorMapping
  );

  const fieldsWithNoTilesCenters = useSelector(
    getFieldsCenterWithNoTilesForNotDeletedFieldBoundaries
  ).filter((field) => field.guid !== editedFieldId); // TODO: move to selector;

  const fieldsWithAnimals = useSelector(fieldWithAnimalsCenters);
  const zoom = mapRef.current?.getMap()?.getZoom();
  const allFields = useSelector(selectAllFields);
  const dryMatterMultiplier = 10000;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Source
        id="field-outlines"
        data={fieldGeoms as FeatureCollection}
        type="geojson"
      >
        <Layer
          id="field-fill"
          type="fill"
          filter={["!=", "id", editMatch?.params.id || ""]}
          paint={{
            "fill-color": isMobile
              ? [
                  "case",
                  ["has", "dryMatterNow"],
                  [
                    "case",
                    ["<", ["get", "dryMatterNow"], 0.07 * dryMatterMultiplier],
                    "#A8917B",
                    ["<", ["get", "dryMatterNow"], 0.1 * dryMatterMultiplier],
                    "#CABFA1",
                    ["<", ["get", "dryMatterNow"], 0.115 * dryMatterMultiplier],
                    "#D6D6AD",
                    ["<", ["get", "dryMatterNow"], 0.13 * dryMatterMultiplier],
                    "#D7E7AA",
                    ["<", ["get", "dryMatterNow"], 0.145 * dryMatterMultiplier],
                    "#D1F094",
                    ["<", ["get", "dryMatterNow"], 0.16 * dryMatterMultiplier],
                    "#C5E97C",
                    ["<", ["get", "dryMatterNow"], 0.175 * dryMatterMultiplier],
                    "#B3E579",
                    ["<", ["get", "dryMatterNow"], 0.19 * dryMatterMultiplier],
                    "#A5DE77",
                    ["<", ["get", "dryMatterNow"], 0.205 * dryMatterMultiplier],
                    "#99D775",
                    ["<", ["get", "dryMatterNow"], 0.22 * dryMatterMultiplier],
                    "#8ED173",
                    ["<", ["get", "dryMatterNow"], 0.235 * dryMatterMultiplier],
                    "#82CA71",
                    ["<", ["get", "dryMatterNow"], 0.25 * dryMatterMultiplier],
                    "#76C36F",
                    ["<", ["get", "dryMatterNow"], 0.265 * dryMatterMultiplier],
                    "#6ABC6D",
                    ["<", ["get", "dryMatterNow"], 0.28 * dryMatterMultiplier],
                    "#62B36C",
                    ["<", ["get", "dryMatterNow"], 0.295 * dryMatterMultiplier],
                    "#59AB6C",
                    ["<", ["get", "dryMatterNow"], 0.31 * dryMatterMultiplier],
                    "#52A36B",
                    ["<", ["get", "dryMatterNow"], 0.34 * dryMatterMultiplier],
                    "#4A9A6A",
                    ["<", ["get", "dryMatterNow"], 0.37 * dryMatterMultiplier],
                    "#41916A",
                    ["<", ["get", "dryMatterNow"], 0.4 * dryMatterMultiplier],
                    "#388969",
                    ["<", ["get", "dryMatterNow"], 0.45 * dryMatterMultiplier],
                    "#318168",
                    ["<", ["get", "dryMatterNow"], 0.5 * dryMatterMultiplier],
                    "#1E7662",
                    [
                      "<",
                      ["get", "dryMatterNow"],
                      0.6001 * dryMatterMultiplier,
                    ],
                    "#0E6E5C",
                    "#008000",
                  ],
                  "#FFFF00",
                ]
              : "transparent",
            "fill-opacity": isMobile ? 1.0 : 0,
          }}
        />
        <Layer
          id="field-fill-no-data"
          type="fill"
          //only show this if a field has no tiles
          filter={["in", ["get", "id"], ["literal", fieldsWithNoTiles]]}
          paint={{ "fill-color": "#666666" }}
        />
        {Object.keys(fieldsByAnimalGroups).map((animalGroupId) => {
          const fieldIdsInAnimalGroup = fieldsByAnimalGroups[animalGroupId].map(
            ({ guid }) => guid
          );
          return (
            <Layer
              key={animalGroupId}
              id={`field-outlines-group-${animalGroupId}`}
              type="line"
              filter={["in", ["get", "id"], ["literal", fieldIdsInAnimalGroup]]}
              paint={{
                "line-color": colorByAnimalGroupId[animalGroupId] || "#666666",
                "line-width": [
                  "interpolate",
                  ["exponential", 2],
                  ["zoom"],
                  10,
                  ["*", 5, ["^", 2, -6]],
                  24,
                  ["*", 5, ["^", 2, 7]],
                ],
                "line-offset": [
                  "interpolate",
                  ["exponential", 2],
                  ["zoom"],
                  10,
                  ["*", 5 / 2 - 1, ["^", 2, -6]],
                  24,
                  ["*", 5 / 2 - 1, ["^", 2, 5]],
                ],
              }}
              layout={{
                "line-join": "miter",
              }}
            />
          );
        })}
      </Source>
      {currentFieldGeom &&
        fieldId &&
        fieldId !== editedFieldId &&
        tmpDeletedFeatures.indexOf(fieldId) === -1 && (
          <Source
            id="field-highlight"
            data={currentFieldGeom as Feature}
            type="geojson"
          >
            <Layer
              id="field-highlight"
              type="line"
              paint={{
                "line-color": "#FFFFFF",
                "line-width": [
                  "interpolate",
                  ["exponential", 2],
                  ["zoom"],
                  10,
                  ["*", 5, ["^", 2, -6]],
                  24,
                  ["*", 5, ["^", 2, 7]],
                ],
                "line-offset": [
                  "interpolate",
                  ["exponential", 2],
                  ["zoom"],
                  10,
                  ["*", 5 / 2 - 1, ["^", 2, -6]],
                  24,
                  ["*", 5 / 2 - 1, ["^", 2, 5]],
                ],
              }}
              layout={{
                "line-join": "miter",
              }}
            />
          </Source>
        )}
      {zoom &&
        zoom >= 13 &&
        !editedFieldId &&
        fieldsWithAnimals.map((field) => {
          const { coordinates } = field.center.geometry;
          return (
            <AnimalsLocationMarker
              zoom={zoom}
              coordinates={coordinates}
              htmlColor={field.color}
              key={field.guid}
              currentField={field}
              fields={fields}
            />
          );
        })}
      {zoom &&
        zoom >= 13 &&
        !editedFieldId &&
        allFields.map((field: Field) => {
          return (
            <DiaryEntryLocationMarkerWithScroll
              setTooltipOverMarker={setTooltipOverMarker}
              key={`diary-locarion-markers-${field.guid}`}
              zoom={zoom}
              currentField={field}
            />
          );
        })}

      {zoom &&
        zoom >= 15 &&
        fieldsWithNoTilesCenters.map((field) => {
          const { coordinates } = field.center.geometry;
          return (
            <Marker
              key={`no-tiles-field-${field.guid}`}
              longitude={coordinates[0]}
              latitude={coordinates[1]}
            >
              <CircularProgress size={20} />
            </Marker>
          );
        })}
    </>
  );
}
