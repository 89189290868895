import {
  Backdrop,
  Dialog,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import LoginBackground from "./LoginDialogBackground.svg";

const PREFIX = "FullScreenDialog";

const classes = {
  paper: `${PREFIX}-paper`,
};

const StyledDiv = styled("div")(({ theme }: { theme: Theme }) => ({
  paddingTop: "env(safe-area-inset-top)",
  paddingBottom: "env(safe-area-inset-bottom)",
}));

const StyledBackdrop = styled(Backdrop)(({ theme }: { theme: Theme }) => ({
  backgroundImage: `url(${LoginBackground})`,
  backgroundSize: "cover",
}));

const StyledDialog = styled(Dialog)(({ theme }: { theme: Theme }) => ({
  minWidth: `${theme.breakpoints.values.xs}px`,
  [`& .${classes.paper}`]: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "450px",
    },
  },
}));

export const FullScreenDialog = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <StyledDialog
      open
      fullScreen={isMobile}
      disableEscapeKeyDown
      BackdropComponent={LoginBackdrop}
      classes={classes}
    >
      <StyledDiv>{children}</StyledDiv>
    </StyledDialog>
  );
};

const LoginBackdrop = () => {
  return <StyledBackdrop open={true} />;
};
